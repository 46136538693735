import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { listFacmanUserSites, listSIGInfraUserSites } from '../graphql/queries';
import * as APIt from "../API";
import { Paths, Regions } from '../constants/Constants';
import { debug, getPACSDataAPIStage } from './commonUtils';

export interface UserSitesParamsInterface {
  loginUsername: string | undefined;
  phySecMember: boolean,
  phySecRegions: string[] | undefined,
  sideTeam: boolean,
  sigAdmin: boolean,
  facmanParams: {
    groupname: string;
    employeeId: number;
    regions?: string[];
    sitename: string;
    username: string;
  }
};

const querySigInfraUserSites = async (stage: string, username: string): Promise<APIt.SIGInfraSite[]> => {
  debug(`querySigInfraUserSites(): stage is ${stage} username is ${username}`);

  let sigSites: APIt.SIGInfraSite[] = [];

  for (let region of Regions) {
    debug(`querySigInfraUserSites(): region is ${region}`);
    const sigSitesResponse = await API.graphql(graphqlOperation(listSIGInfraUserSites,
      {
        path: `${Paths.SiteFinder}/region/${region}/stage/${getPACSDataAPIStage(stage)}/?login=${username}&OutputFormat=json`
      })) as GraphQLResult<APIt.ListSIGInfraUserSitesQuery>;
    debug(`querySigInfraUserSites(): sigSitesResponse is ${JSON.stringify(sigSitesResponse)}`);
    let sigInfraSites = sigSitesResponse.data?.listSIGInfraUserSites as APIt.SIGInfraSite[];
    sigInfraSites = sigInfraSites.map(site => { return {...site, Region: region} } );
    sigSites = [...sigSites, ...sigInfraSites];
  }
  return sigSites;
};

export const fetchUserSites = async (params: UserSitesParamsInterface): Promise<APIt.UserPrefSite[]> => {
  debug(`fetchUserSites(): params is ${JSON.stringify(params)}`);

  let stage = 'gamma';
  debug(`fetchUserSites(): stage is ${stage} ${stage.toUpperCase()}`);

  let userSites: APIt.UserPrefSite[] = [];

  if (!params.loginUsername) return userSites;

  try {
    const sigSites = await querySigInfraUserSites(stage, params.loginUsername);
    debug(`fetchUserSites(): sigSites is ${JSON.stringify(sigSites)}`);

    let facmanSitesResponse: GraphQLResult<APIt.ListFacmanUserSitesQuery>;
    if (params.sideTeam || params.sigAdmin || params.phySecMember) {
      params.facmanParams = {
        employeeId: 0,
        groupname: '',
        sitename: '',
        username: ''
      }
      if (params.phySecRegions && params.phySecRegions.length > 0) {
        params.facmanParams.regions = params.phySecRegions;
      }
    }
    debug(`fetchUserSites(): params.facmanParams is ${JSON.stringify(params.facmanParams)}`);
    facmanSitesResponse = await API.graphql(graphqlOperation(listFacmanUserSites,
      {
        sitename: params.facmanParams.sitename,
        groupname: params.facmanParams.groupname,
        regions: params.facmanParams.regions,
        username: params.facmanParams.username,
        employeeId: params.facmanParams.employeeId
      })) as GraphQLResult<APIt.ListFacmanUserSitesQuery>;
    debug(`fetchUserSites(): facmanSitesResponse is ${JSON.stringify(facmanSitesResponse)}`);

    let facmanSitesResponseError = facmanSitesResponse.data?.listFacmanUserSites?.error;
    debug(`fetchUserSites(): facmanSitesResponseError is ${facmanSitesResponseError}`);
    if (facmanSitesResponseError) throw(facmanSitesResponseError);

    let facmanSites = facmanSitesResponse.data?.listFacmanUserSites?.sites as APIt.FacmanSite[];
    debug(`fetchUserSites(): facmanSites is ${JSON.stringify(facmanSites)}`);
    userSites = facmanSites.filter(fs => fs.siteName !== undefined && fs.region !== 'unknown').map(fs => {
      return { __typename: 'UserPrefSite', sitename: fs.siteName!, siteRegion: fs.region! }
    });
    let sigSitesMapped: APIt.UserPrefSite[] = sigSites.filter(ss => ss.SiteCode !== undefined && ss.Region !== 'unknown')
      .map(ss => { return { __typename: 'UserPrefSite', sitename: ss.SiteCode!, siteRegion: ss.Region } });
    userSites.push(...sigSitesMapped);
    debug(`fetchUserSites(): userSites is ${JSON.stringify(userSites)}`);
    let uniqueSites = Array.from(new Set(userSites.map(v => JSON.stringify(v)))).map(v => JSON.parse(v)).sort();
    debug(`fetchUserSites(): uniqueSites is ${JSON.stringify(uniqueSites)}`);
    return uniqueSites;
  } catch(error) {
    console.error(`fetchUserSites(): error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
    throw error;
  }
}

export enum Privileges {
  all = 'All',
  maskInputs = 'Mask Inputs'
};

export const fetchUserSitePrivs = async (employeeId: number, username: string, sitename: string, groupname: string = ''): Promise<Privileges[]> => {
  debug(`fetchUserSitePrivs(): username is ${username} sitename is ${sitename} groupname is ${groupname}`);

  const userSitePrivs: Privileges[] = [];

  if (!username || !sitename) return userSitePrivs;

  let stage = 'gamma';
  debug(`fetchUserSitePrivs(): stage is ${stage} ${stage.toUpperCase()}`);

  try {
    const facmanSitesResponse = await API.graphql(graphqlOperation(listFacmanUserSites,
      {
        sitename: sitename,
        groupname: groupname,
        username: username,
        employeeId: employeeId
      })) as GraphQLResult<APIt.ListFacmanUserSitesQuery>;
    debug(`fetchUserSitePrivs(): facmanSitesResponse is ${JSON.stringify(facmanSitesResponse)}`);
    let facmanSites = facmanSitesResponse.data?.listFacmanUserSites?.sites as APIt.FacmanSite[];
    debug(`fetchUserSitePrivs(): facmanSites is ${JSON.stringify(facmanSites)}`); 
    if (facmanSites.length > 0) {
      userSitePrivs.push(Privileges.all);
      debug(`fetchUserSitePrivs(): userSitePrivs is ${JSON.stringify(userSitePrivs)}`);
      return userSitePrivs;
    } 
    const sigSites = await querySigInfraUserSites(stage, username);
    debug(`fetchUserSitePrivs(): sigSites is ${JSON.stringify(sigSites)}`);
    if (sigSites.map(ss => ss.SiteCode).includes(sitename)) userSitePrivs.push(Privileges.maskInputs);
    debug(`fetchUserSitePrivs(): userSitePrivs is ${JSON.stringify(userSitePrivs)}`);
    return userSitePrivs;
  } catch(error) {
    console.error(`fetchUserSitePrivs(): error is ${error} JSON.stringify: ${JSON.stringify(userSitePrivs)}`);
    throw error;
  }
}